import axios from 'axios';


  // const API_URL = 'http://localhost:5000/api/ewallet/';

let API_URL = ''
if(process.env.NODE_ENV === 'development') {
   API_URL = 'http://localhost:5000/api/ewallet/';
} else {
   API_URL =  '//api.siamprai-login.com/api/ewallet/';

}


const AdminUpdateEwallet = async (data, token) => {
   const config = {
      headers: {
         Authorization: `Bearer ${token}`,
      },
   };

   const response = await axios.put(API_URL + 'admin/' + data.id, data, config);
   return response.data;
};

const updateEwallet = async (data, token) => {
   const config = {
      headers: {
         Authorization: `Bearer ${token}`,
      },
   };

   const response = await axios.put(API_URL + data.id, data, config);
   return response.data;
};

const getEwallet = async (page,token) => {
   const config = {
      headers: {
         Authorization: `Bearer ${token}`,
      },
   };

   const limit = 10


   const response = await axios.get(`${API_URL}?page=${page}&limit=${limit}`, config);
   return response.data;
};
const getPV = async (token) => {
   const config = {
      headers: {
         Authorization: `Bearer ${token}`,
      },
   };


   const response = await axios.get(API_URL + 'getPV', config);
   return response.data;
};

const getByMonth = async (token) => {
   const config = {
      headers: {
         Authorization: `Bearer ${token}`,
      },
   };

   const response = await axios.get(API_URL + 'getbymonth', config);
   return response.data;
};

const getCenterTransfer = async (token) => {
   const config = {
      headers: {
         Authorization: `Bearer ${token}`,
      },
   };

   const response = await axios.get(API_URL + 'getCenterTransfer', config);
   return response.data;
};

const getCenterDeposit = async (token) => {
   const config = {
      headers: {
         Authorization: `Bearer ${token}`,
      },
   };

   const response = await axios.get(API_URL + 'getCenterDeposit', config);
   return response.data;
};

const ewalletService = {
   updateEwallet,
   AdminUpdateEwallet,
   getEwallet,
   getCenterDeposit,
   getCenterTransfer,
   getByMonth,
   getPV
};

export default ewalletService;
