import './App.css';
import React from 'react';
import {
   BrowserRouter as Router,
   Routes,
   Route,
   Navigate,
} from 'react-router-dom';

import { ToastContainer } from 'react-toastify';

import HomePage from './pages/HomePage';
import LoginPage from './pages/LoginPage';
import CenterPage from './pages/CenterPage';
import ProfileEditPage from './pages/ProfileEditPage';
import DepositPage from './pages/DepositPage';
import HistoryDepositPage from './pages/HistoryDepositPage';
import HistoryTransferPage from './pages/HistoryTransferPage';
import TransferPage from './pages/TransferPage';

import MemberPage from './pages/MemberPage';
import ResetPasswordPage from './pages/ResetPasswordPage';
import CenterDetail from './pages/CenterDetail';

import RegisterPage from './pages/RegisterPage';
import RecommenderPage from './pages/RecommenderPage';
import OrderPage from './pages/OrderPage';

import OrdersPage from './pages/OrdersPage';
import OrderDetail from './pages/OrderDetail';

import BonusHistory from './pages/BonusHistory';
import BinaryPointHistory from './pages/BinaryPointHistory';
import BonusMatchingSummaryNew from './pages/BonusMatchingSummaryNew';
import MatchngDetailsNew from './pages/MatchngDetailsNew';
import BinaryPointHistoryNew from './pages/BinaryPointHistoryNew';

import TransferEwallet from './centerPages/TransferEwallet';
import HistoryDepositEwallet from './centerPages/HistoryDepositEwallet';
import SummaryWalletMonth from './centerPages/SummaryWalletMonth';
import HistoryTransferEwallet from './centerPages/HistoryTransferEwallet';

import SpExchange from './pages/SpExchange';

import RecommenderPageDetail from './pages/RecommenderPageDetail';

import BonusMobileDetails from './pages/BonusMobileDetails';
import SumByCardId from './pages/SumByCardId';
import BinaryView3 from './pages/BinaryView3';
import OrderToPay from './pages/OrderToPay';
import OrderRenewToPay from './pages/OrderRenewToPay';
import BonusBinaryDetail from './pages/BonusBinaryDetail';
import LiveScoreDetail from './pages/LiveScoreDetail';
import BonusesUsers from './pages/BonusesUsers';
import UsersDetail from './pages/UsersDetail';
import ApproveDeposit from './pages/ApproveDeposit';

function App() {
   return (
      <>
         <ToastContainer />
         <Router basename='/'>
            <Routes>
               <Route path='/' element={<App />} />
               <Route
                  index
                  element={
                     <ProtectedRoute>
                        <HomePage />{' '}
                     </ProtectedRoute>
                  }
               />

               {/* Center Section */}
               <Route
                  path='center'
                  element={
                     <ProtectedRoute>
                        <CenterPage />{' '}
                     </ProtectedRoute>
                  }
               />
               <Route
                  path='center/historydepostwallet'
                  element={
                     <ProtectedRoute>
                        <HistoryDepositEwallet />{' '}
                     </ProtectedRoute>
                  }
               />

               <Route
                  path='center/transfer'
                  element={
                     <ProtectedRoute>
                        <TransferEwallet />{' '}
                     </ProtectedRoute>
                  }
               />
               <Route
                  path='center/summarywalletmonth'
                  element={
                     <ProtectedRoute>
                        <SummaryWalletMonth />{' '}
                     </ProtectedRoute>
                  }
               />

               <Route
                  path='center/historytranserwallet'
                  element={
                     <ProtectedRoute>
                        <HistoryTransferEwallet />{' '}
                     </ProtectedRoute>
                  }
               />

            
               <Route
                  path='/binary/:userId/:username/:prevUserId'
                  element={
                     <ProtectedRoute>
                        <BinaryView3 />
                     </ProtectedRoute>
                  }
               />

           
               <Route
                  path='/binary/:userId/:username'
                  element={
                     <ProtectedRoute>
                        <BinaryView3 />{' '}
                     </ProtectedRoute>
                  }
               />
           
               <Route
                  path='binary'
                  element={
                     <ProtectedRoute>
                        <BinaryView3 />{' '}
                     </ProtectedRoute>
                  }
               />
               <Route
                  path='lineRecommender'
                  element={
                     <ProtectedRoute>
                        <RecommenderPage />{' '}
                     </ProtectedRoute>
                  }
               />
               <Route
                  path='lineRecommenderDetail'
                  element={
                     <ProtectedRoute>
                        <RecommenderPageDetail />{' '}
                     </ProtectedRoute>
                  }
               />
               <Route
                  path='center/:id'
                  element={
                     <ProtectedRoute>
                        <CenterDetail />{' '}
                     </ProtectedRoute>
                  }
               />
               <Route
                  path='profile'
                  element={
                     <ProtectedRoute>
                        <ProfileEditPage />{' '}
                     </ProtectedRoute>
                  }
               />
               <Route
                  path='spexchange'
                  element={
                     <ProtectedRoute>
                        <SpExchange />{' '}
                     </ProtectedRoute>
                  }
               />
               <Route
                  path='deposit'
                  element={
                     <ProtectedRoute>
                        <DepositPage />{' '}
                     </ProtectedRoute>
                  }
               />
               <Route
                  path='confirm-deposit/:id'
                  element={
                     <ProtectedRoute>
                        <ApproveDeposit />{' '}
                     </ProtectedRoute>
                  }
               />
               <Route
                  path='bonusHistory'
                  element={
                     <ProtectedRoute>
                        <BonusHistory />{' '}
                     </ProtectedRoute>
                  }
               />

               <Route
                  path='BonusBinaryDetail/:date'
                  element={
                     <ProtectedRoute>
                        <BonusBinaryDetail />{' '}
                     </ProtectedRoute>
                  }
               />

               <Route
                  path='bonusMobileDetails/:date'
                  element={
                     <ProtectedRoute>
                        <BonusMobileDetails />{' '}
                     </ProtectedRoute>
                  }
               />

               <Route
                  path='binarypointhistorynew'
                  element={
                     <ProtectedRoute>
                        <BinaryPointHistoryNew />{' '}
                     </ProtectedRoute>
                  }
               />

               <Route
                  path='bonusmatchingsummary/:date/:amount1/:amount2'
                  element={
                     <ProtectedRoute>
                        <BonusMatchingSummaryNew />{' '}
                     </ProtectedRoute>
                  }
               />
               <Route
                  path='matchingDetailNew/:date/:id'
                  element={
                     <ProtectedRoute>
                        <MatchngDetailsNew />{' '}
                     </ProtectedRoute>
                  }
               />
               <Route
                  path='matchingDetailNew/:date/:users2/:id'
                  element={
                     <ProtectedRoute>
                        <MatchngDetailsNew />{' '}
                     </ProtectedRoute>
                  }
               />
                <Route
                  path='bonusesUsers'
                  element={
                     <ProtectedRoute>
                        <BonusesUsers />{' '}
                     </ProtectedRoute>
                  }
               />
                <Route
                  path='usersDetail'
                  element={
                     <ProtectedRoute>
                        <UsersDetail />{' '}
                     </ProtectedRoute>
                  }
               />
               <Route
                  path='binarypointhistorynew'
                  element={
                     <ProtectedRoute>
                        <BinaryPointHistory />{' '}
                     </ProtectedRoute>
                  }
               />
               <Route
                  path='liveScoreDetail/:side'
                  element={
                     <ProtectedRoute>
                        <LiveScoreDetail />{' '}
                     </ProtectedRoute>
                  }
               />

               <Route
                  path='transfer'
                  element={
                     <ProtectedRoute>
                        <TransferPage />{' '}
                     </ProtectedRoute>
                  }
               />
               <Route
                  path='depositrecord'
                  element={
                     <ProtectedRoute>
                        <HistoryDepositPage />{' '}
                     </ProtectedRoute>
                  }
               />
               <Route
                  path='deposit'
                  element={
                     <ProtectedRoute>
                        <DepositPage />{' '}
                     </ProtectedRoute>
                  }
               />

               <Route
                  path='member'
                  element={
                     <ProtectedRoute>
                        <MemberPage />{' '}
                     </ProtectedRoute>
                  }
               />
               <Route
                  path='transferrecord'
                  element={
                     <ProtectedRoute>
                        <HistoryTransferPage />{' '}
                     </ProtectedRoute>
                  }
               />
               <Route
                  path='updatePassword'
                  element={
                     <ProtectedRoute>
                        <ResetPasswordPage />{' '}
                     </ProtectedRoute>
                  }
               />
               <Route
                  path='register'
                  element={
                     <ProtectedRoute>
                        <RegisterPage />{' '}
                     </ProtectedRoute>
                  }
               />
               <Route
                  path='register/:parentId/:parentName/:side/:userSideId/:userSideName'
                  element={
                     <ProtectedRoute>
                        <RegisterPage />{' '}
                     </ProtectedRoute>
                  }
               />
               <Route
                  path='order'
                  element={
                     <ProtectedRoute>
                        <OrderPage />{' '}
                     </ProtectedRoute>
                  }
               />
               <Route
                  path='orders'
                  element={
                     <ProtectedRoute>
                        <OrdersPage />{' '}
                     </ProtectedRoute>
                  }
               />
               <Route
                  path='orders/orderDetail/:id'
                  element={
                     <ProtectedRoute>
                        <OrderDetail />{' '}
                     </ProtectedRoute>
                  }
               />
               <Route
                  path='orders/orderCheckBill/:name/:pv'
                  element={
                     <ProtectedRoute>
                        <OrderToPay />{' '}
                     </ProtectedRoute>
                  }
               />
               <Route
                  path='orders/orderCheckBillRenew/:name/:pv'
                  element={
                     <ProtectedRoute>
                        <OrderRenewToPay />{' '}
                     </ProtectedRoute>
                  }
               />
               <Route
                  path='cardId'
                  element={
                     <ProtectedRoute>
                        <SumByCardId />{' '}
                     </ProtectedRoute>
                  }
               />



               <Route path='login' element={<LoginPage />} />
            </Routes>
         </Router>
      </>
   );
}

export default App;

export function ProtectedRoute({ children }) {
   if (localStorage.getItem('userInfo')) {
      return children;
   } else {
      return <Navigate to='/login' />;
   }
}

export function ProtectAdmin({ children }) {
   const isAdmin = localStorage.getItem('userInfo', 'isAdmin');
   if (isAdmin) {
      return children;
   } else {
      return <Navigate to='/login' />;
   }
}
