import React, { useEffect, useRef, useState } from 'react';
import DefaultLayout from '../components/DefaultLayout';
import {
   Button,
   Form,
   Input,
   Modal,
   Result,
   Select,
   Radio,
   Space,
   Image,
   Alert,
   Checkbox,
} from 'antd';
import Loading from '../components/Loading';
import { ImUserPlus } from 'react-icons/im';

import { useLocation, useNavigate, useParams } from 'react-router-dom';
import SearchInput from '../components/form/SearchInput';

import { AiOutlineArrowDown } from 'react-icons/ai';
import {
   ThailandAddressTypeahead,
   ThailandAddressValue,
} from 'react-thailand-address-typeahead';

import { toast } from 'react-toastify';

import * as thaiId from 'thaiid';

import BBL from '../assets/icon/th/bbl.svg';
import KBANK from '../assets/icon/th/kbank.svg';
import KTB from '../assets/icon/th/ktb.svg';
import SCB from '../assets/icon/th/scb.svg';
import UOB from '../assets/icon/th/uob.svg';
import BAY from '../assets/icon/th/bay.svg';
import GSB from '../assets/icon/th/gsb.svg';
import GHB from '../assets/icon/th/ghb.svg';
import IBANK from '../assets/icon/th/ibank.svg';
import TISCO from '../assets/icon/th/tisco.svg';
import LHB from '../assets/icon/th/lhb.svg';
import BAAC from '../assets/icon/th/baac.svg';
import TTB from '../assets/icon/th/ttb.png';

import ABA from '../assets/icon/cambodia/aba-bank.png';
import ACLEDA from '../assets/icon/cambodia/acleda-bank2.png';

import styled from 'styled-components';

import {
   register,
   register3,
   register7,
   register15,
   reset,
   checkMemberLine,
   getDatafromCardId,
} from '../features/users/usersSlice';
import { useDispatch, useSelector } from 'react-redux';
import Permission from '../components/Permission';
import { getSetting } from '../features/setting/settingSlice';
import { getProducts } from '../features/product/productSlice';
import SearchInputForUpline from '../components/form/SearchInputForUpline';
const { TextArea } = Input;

const { Option } = Select;
const options = [
   {
      label: 'ชาย',
      value: 'ชาย',
   },
   {
      label: 'หญิง',
      value: 'หญิง',
   },
];

const RegisterPage = () => {
   const navigate = useNavigate();

   const lang = useSelector((state) => state.language.lang);

   const { userFormCardId, userFormCardIdError, userFormCardIdMessage } =
      useSelector((state) => state.user);

   const [recommendedBy, setRecommendedBy] = useState('');
   const [memberUpline, setMemberUpline] = useState('');

   const [productItems, setProductItems] = useState({
      GenevaQty: 0,
      SiamPraiqty: 1,
   });

   const scrollRef = useRef(null);

   const [val, setVal] = useState(ThailandAddressValue.empty());

   const [isModalOpen, setIsModalOpen] = useState(false);
   const [modalValidate, setModalValidate] = useState(false);
   const [modalError, setModalError] = useState(false);
   const [modalError2, setModalError2] = useState(false);
   const [modalAgreement, setModalAgreement] = useState(false);

   const [data, setData] = useState({});

   const [disabledRight, setDisabledRight] = useState(true);
   const [disabledLeft, setDisabledLeft] = useState(true);
   const [packageSelected, setPackageSelected] = useState('');

   const [isThai, setIsThai] = useState(false);
   const [isForeiger, setIsForeige] = useState(false);
   const [isCambodia, setIsCambodia] = useState(false);
   const [qtx, setQtx] = useState(1);

   const [text, setText] = useState('');
   const [loading, setLoading] = useState(false);

   const [loadingPage, setLoadingPage] = useState(true);

   const { isLoading, isError, registerSuccess, message, check } = useSelector(
      (state) => state.user
   );

   const {
      parentId,
      parentName,
      userSideId,
      userSideName,
      side: initialSide,
   } = useParams();

   let side = initialSide || ''; // If initialSide is undefined or empty, assign ''

   const dispatch = useDispatch();

   const agreeMentRef = useRef();
   const [agreeMent, setAgreeMent] = useState(false);
   const recId = localStorage.getItem('recomId');
   const recName = localStorage.getItem('recomName');

   const [userUplineId, setUserUplineId] = useState(parentId);
   const [userUplineName, setUserUplineName] = useState(parentName);
   const [upLineData, setUpLineData] = useState(
      userUplineId + ' - ' + userUplineName
   );

   const [uplineFromSearch, setUplineFromSearch] = useState('');

   // const upLineData = userUplineId + ' - ' + userUplineName;

   const [sideData, setSideData] = useState('');
   const [value, setValue] = useState('');
   const [value2, setValue2] = useState('');
   const [value3, setValue3] = useState('');
   const [value4, setValue4] = useState(1);

   const [checkSideLeft, setCheckSideLeft] = useState(false);
   const [checkSideRight, setCheckSideRight] = useState(false);

   const sendDataToParent = (i) => {
      setRecommendedBy(i.userId);
      setMemberUpline(i.userId);
   };
   const sendDataToParent2 = (i) => {
      if (i.message === 'ฝั่งขวาเต็มแล้ว') {
         setCheckSideRight(true);
      } else if (i.message === 'ฝั่งซ้ายเต็มแล้ว') {
         setCheckSideLeft(true);
      }
      setUpLineData(i.userUpline.userId + ' - ' + i.userUpline.name);

      setUserUplineId(i.userUpline.userId);
      setUserUplineName(i.userUpline.name);
      setUplineFromSearch(i.userUpline.userId);
   };

   const onChange = (e) => {
      setValue(e.target.value);
   };
   const onChange2 = (e) => {
      setValue2(e.target.value);
   };

   const onChange3 = (e) => {
      setValue3(e.target.value);
   };

   const [form] = Form.useForm();

   let isCheck = check;

   useEffect(() => {
      setModalAgreement(true);
      dispatch(getProducts());
      return () => {
         dispatch(reset());
      };
   }, [dispatch]);

   useEffect(() => {
      if (recommendedBy && userUplineId) {
         const newOb = {
            userUplineId: userUplineId,
            recommendedBy: recommendedBy,
         };

         dispatch(checkMemberLine(newOb));
      }
   }, [recommendedBy, userUplineId]);

   useEffect(() => {
      if (recId) setRecommendedBy(recId);
      if (isCheck === false) setIsModalOpen(true);

      dispatch(getSetting());

      if (side === 'R') {
         setDisabledRight(false);
         setValue('-');
         setSideData('ขวา');
      } else if (side === 'L') {
         setDisabledLeft(false);
         setValue(2);
         setSideData('ซ้าย');
      }

      if (loadingPage) {
         setTimeout(() => {
            setLoadingPage(false);
         }, 1500);
      }

      if (isError === true) {
         setModalError(true);
      }

      if (userFormCardIdError) {
         setModalError2(true);
      }

      form.setFieldsValue({
         upLineData: upLineData,
         recommendedBy: recommendedBy,
         memberUpline: memberUpline,

         sideData: sideData,
      });

      if (text.length === 0 && loading) setLoading(false);
   }, [
      text,
      sideData,
      isCheck,
      userUplineId,
      recommendedBy,
      dispatch,
      isError,
      userFormCardIdError,
   ]);

   const [cardId, setCardId] = useState('');
   const [bankAccount, setBankAccount] = useState('');
   const [bankAccount2, setBankAccount2] = useState('');

   useEffect(() => {
      if (cardId.length === 13) {
         let parentId = '';
         if (parentId) {
            parentId = parentId;
         } else {
            parentId = userUplineId;
         }

         let data = {};

         if (parentId) {
            data = { cardId, uplineId: parentId };
         } else {
            data = { cardId, uplineId: userUplineId };
         }

         dispatch(getDatafromCardId(data));
      }
   }, [dispatch, cardId, parentId]);

   const handleCheckboxChange = (e) => {
      agreeMentRef.current = e.target.checked;
      setAgreeMent(e.target.checked);
   };

   const onFinish = (values) => {
      values.memberKeys = qtx;

      values.items = productItems;

      val.address = values.address;
      values.address = val;

      values.cardId = cardId;
      if (agreeMentRef.current === true) {
         values.agreeMent = agreeMentRef.current;
      } else {
         values.agreeMent = false;
      }

      if (values.upline === undefined) {
         values.upline = side;
      }

      if (userFormCardId.name) {
         let data;
         if (Object.isExtensible(userFormCardId)) {
            data = userFormCardId;
         } else {
            data = { ...userFormCardId };
         }

         data.items = productItems;
         data.memberKeys = qtx;

         data.username = userFormCardId.name;
         data.address =
            data.address && typeof data.address === 'object'
               ? data.address
               : {};
         data.address.address = userFormCardId.address || '';
         data.address.province = userFormCardId.province;
         data.address.district = userFormCardId.district || '';
         data.address.subdistrict = userFormCardId.subdistrict || '';
         data.address.postalCode = userFormCardId.postalCode || '';

         data.upLineData = upLineData;
         data.recommendedBy = recommendedBy;
         data.package1 = values.package1;
         data.MemType = values.MemType;

         if (data.upline === undefined) {
            data.upline = side;
         }

         if (qtx == 1) {
            dispatch(register(data));
         } else if (qtx === 3) {
            dispatch(register3(data));
         } else if (qtx === 7) {
            dispatch(register7(data));
         } else if (qtx === 15) {
            dispatch(register15(data));
         }
      } else {
         values.recommendedBy = recommendedBy;
         if (qtx == 1) {
            dispatch(register(values));
         } else if (qtx === 3) {
            dispatch(register3(values));
         } else if (qtx === 7) {
            dispatch(register7(values));
         } else if (qtx === 15) {
            dispatch(register15(values));
         }
      }
   };
   if (registerSuccess) {
      toast.success('สมัครสมาชิกสำเร็จ');

      if (parentId) {
         navigate(`/binary/${parentId}/${parentName}`);
      } else {
         navigate(`/binary/${userUplineId}/${userUplineName}`);
      }
   }
   if (isError) {
   }

   const modalScroll = () => {
      const clickRef = scrollRef.current;

      if (clickRef) {
         clickRef.scrollIntoView({ behavior: 'smooth' });
      }
   };

   const [totalQty, setTotalQty] = useState(0);

   return (
      <DefaultLayout>
         {loadingPage || isLoading ? (
            <>
               <Loading />
            </>
         ) : (
            <>
               {modalAgreement && (
                  <Modal
                     style={{ height: '400px' }}
                     maskClosable={false}
                     closable={false}
                     open={modalAgreement}
                     visible={modalAgreement}
                     onOk={() => setModalAgreement(false)}
                     onCancel={() => setModalAgreement(false)}
                     footer={[
                        <>
                           <div className='d-flex flex-row justify-content-between'>
                              <div>
                                 <AiOutlineArrowDown
                                    size={32}
                                    className='scroll mr-6'
                                    style={{
                                       cursor: 'pointer',
                                       marginRight: '10px',
                                    }}
                                    onClick={modalScroll}
                                 />
                              </div>
                              <div>
                                 {agreeMent !== true ? (
                                    <Button
                                       style={{ backgroundColor: 'gray' }}
                                       disabled
                                       onClick={() => setModalAgreement(false)}
                                    >
                                       ตกลง
                                    </Button>
                                 ) : (
                                    <Button
                                       type='primary'
                                       onClick={() => setModalAgreement(false)}
                                    >
                                       ตกลง
                                    </Button>
                                 )}
                              </div>
                           </div>
                        </>,
                     ]}
                  >
                     <div>
                        <b style={{ fontSize: '16px' }}>ข้อตกลงในการสมัคร</b>
                        <div className='register__permission'>
                           <div className='text-start'>
                              <p>
                                 ข้อตกลงนี้เป็นข้อตกลงระหว่างบริษัท
                                 และบุคคลที่ซื้อผลิตภัณฑ์และสมัครเป็นสมาชิกกับบริษัท
                                 ตามรายละเอียดดังต่อไปนี้
                              </p>
                              <p>
                                 {' '}
                                 บริษัทประกอบธุรกิจขายผลิตภัณฑ์เพื่อสุขภาพ ฯลฯ
                                 ซึ่งต่อไปนี้จะเรียกว่า "บริษัท"
                                 สมาชิกคือบุคคลที่สั่งซื้อผลิตภัณฑ์ของบริษัทและลงทะเบียนเป็นสมาชิกของเครือข่ายของบริษัทเพื่อช่วยขายผลิตภัณฑ์หรือกระจายผลิตภัณฑ์และสามารถสั่งซื้อผลิตภัณฑ์ในราคาที่บริษัทกำหนด
                                 และยังได้รับค่าตอบแทนจากการแนะนำผู้อื่นให้ซื้อผลิตภัณฑ์และสมัครเป็นสมาชิกเช่นกัน
                                 ซึ่งต่อไปนี้จะเรียกว่า "สมาชิก"
                              </p>
                           </div>
                           <Permission />
                           <h1 ref={scrollRef}></h1>
                           <div className='d-flex justify-content-center mt-3'>
                              <Checkbox
                                 onChange={handleCheckboxChange}
                                 value={true}
                              >
                                 <span>
                                    <b style={{ fontSize: '16px' }}>ยอมรับ</b>
                                 </span>
                              </Checkbox>
                           </div>
                        </div>
                     </div>
                  </Modal>
               )}
               <div className=' mt-5 form-block-register'>
                  {isError && (
                     <>
                        <Alert
                           message='ในการสมัครสมาชิก หมายเลขบัตรประชาชน 1 หมายเลขสามารถสมัครได้เพียง 18 รหัสเท่านั้น'
                           type='error'
                           closable
                           showIcon
                        />
                        <br />
                        <Alert
                           message={message}
                           type='error'
                           closable
                           showIcon
                        />
                     </>
                  )}
                  {userFormCardIdError && (
                     <>
                        <Alert
                           message={userFormCardIdMessage}
                           type='error'
                           closable
                           showIcon
                        />
                     </>
                  )}
                  <div>
                     <Alert
                        status='warning'
                        message='ในการสมัครสมาชิก หมายเลขบัตรประชาชน 1 หมายเลขสามารถสมัครได้เพียง 18 รหัสเท่านั้น'
                     />
                     <br />
                  </div>
                  <br />
                  <h3 className='text-center'>
                     <b>สมัครสมาชิกใหม่</b>
                  </h3>
                  <br />
                  <Form
                     form={form}
                     name='basic'
                     labelCol={{
                        span: 6,
                     }}
                     wrapperCol={{
                        span: 18,
                     }}
                     initialValues={{
                        upLineData: upLineData,
                        recommendedBy: recommendedBy,
                        memberUpline: memberUpline,
                     }}
                     onFinish={onFinish}
                     autoComplete='off'
                  >
                     <Form.Item
                        label={
                           <div className=''>
                              <span style={{ fontSize: '18px' }}>
                                 {lang === 'TH'
                                    ? 'ผู้แนะนำ'
                                    : lang === 'ENG'
                                    ? 'Sponsor'
                                    : lang === 'KH'
                                    ? 'អ្នកណែនាំ'
                                    : 'ผู้แนะนำ'}
                              </span>
                           </div>
                        }
                     >
                        <SearchInput
                           //      upLineData={userUplineId}
                           value={recommendedBy}
                           sendDataToParent={sendDataToParent}
                        />
                        <Button
                           type='danger'
                           onClick={() => {
                              localStorage.removeItem('recomId');
                              localStorage.removeItem('recomName');
                              window.location.reload();
                           }}
                        >
                           CLEAR
                        </Button>
                     </Form.Item>
                     {parentName ? (
                        <Form.Item
                           style={{ color: 'rgb(0,0,0)' }}
                           label={<ImUserPlus size={16} />}
                           name='upLineData'
                           rules={[
                              {
                                 required: true,
                                 message: 'ข้อมูลอัพไลน์',
                              },
                           ]}
                        >
                           <Input
                              style={{
                                 color: 'red',
                              }}
                              disabled
                              placeholder={upLineData}
                              value={upLineData}
                           />
                        </Form.Item>
                     ) : (
                        <Form.Item
                           name='upLineData'
                           style={{ color: 'red ' }}
                           label={
                              <div>
                                 <span style={{ fontSize: '18px' }}>
                                    {' '}
                                    อัพไลน์{' '}
                                 </span>
                              </div>
                           }
                           rules={[
                              {
                                 required: true,
                                 message: 'ข้อมูลอัพไลน์',
                              },
                           ]}
                        >
                           <SearchInputForUpline
                              upLineData={userUplineId}
                              value={userUplineId}
                              sendDataToParent2={sendDataToParent2}
                           />
                           <Button
                              type='danger'
                              onClick={() => {
                                 localStorage.removeItem('uplineId');
                                 localStorage.removeItem('uplineName');
                                 window.location.reload();
                              }}
                           >
                              CLEAR
                           </Button>
                        </Form.Item>
                     )}
                     <br />

                     <Form.Item
                        name='upline'
                        label={
                           lang === 'TH'
                              ? 'ด้าน'
                              : lang === 'ENG'
                              ? 'Side'
                              : lang === 'KH'
                              ? 'ផ្នែក'
                              : 'Side'
                        }
                     >
                        <Radio.Group
                           onChange={onChange}
                           value={side && side === 'L' ? 'L' : 'R'}
                        >
                           <Space direction='vertical'>
                              <Radio
                                 disabled={
                                    (side === 'R' && userSideName) ||
                                    checkSideLeft
                                 }
                                 value='L'
                              >
                                 {lang === 'TH'
                                    ? 'ซ้าย'
                                    : lang === 'ENG'
                                    ? 'Left'
                                    : lang === 'KH'
                                    ? 'ឆ្វេង'
                                    : 'ซ้าย'}
                                 {side === 'R' && (
                                    <span>
                                       {' '}
                                       {userSideId === 'null' ? (
                                          <></>
                                       ) : (
                                          <>
                                             {userSideId} - {userSideName}
                                          </>
                                       )}
                                    </span>
                                 )}
                              </Radio>

                              <Radio
                                 disabled={
                                    (side === 'L' && userSideName) ||
                                    checkSideRight
                                 }
                                 value='R'
                              >
                                 {lang === 'TH'
                                    ? 'ขวา'
                                    : lang === 'ENG'
                                    ? 'Right'
                                    : lang === 'KH'
                                    ? 'ស្ដាំ'
                                    : 'ขวา'}
                                 {side === 'L' && (
                                    <span>
                                       {' '}
                                       {userSideId === 'null' ? (
                                          <></>
                                       ) : (
                                          <>
                                             {userSideId} - {userSideName}
                                          </>
                                       )}
                                    </span>
                                 )}
                              </Radio>
                           </Space>
                        </Radio.Group>
                     </Form.Item>

                     <Form.Item
                        name='package1'
                        label={
                           lang === 'TH'
                              ? 'ข้อมูลแพ็คเกจ'
                              : lang === 'ENG'
                              ? 'Package Information'
                              : lang === 'KH'
                              ? 'ព័ត៌មានផ្តល់ជូន'
                              : 'Package Information'
                        }
                        rules={[
                           {
                              required: true,

                              message: 'ข้อมูลแพ็คเกจ',
                           },
                        ]}
                     >
                        <Select
                           placeholder='กรุณาเลือกตำแหน่ง'
                           onChange={(value) => {
                              setPackageSelected(value);
                              if (value === 'Tester') {
                                 setQtx(1);
                                 setTotalQty(1);
                              } else if (value === 'Member') {
                                 setQtx(1);
                                 setTotalQty(1);
                              }
                           }}
                        >
                           <Option value='Tester'>TESTER</Option>
                           <Option value='Member'> MEMBER</Option>
                           <Option value='Holder'>HOLDER</Option>
                        </Select>
                     </Form.Item>

                     {packageSelected === 'Holder' && (
                        <>
                           <Form.Item label='จำนวนรหัส'>
                              <Select
                                 placeholder='กรุณาเลือกจำนวนรหัส'
                                 key={1}
                                 onChange={(value) => {
                                    setQtx(value);
                                    setTotalQty(value);
                                 }}
                              >
                                 <Option value={1}>1</Option>
                                 <Option value={3}>3</Option>
                                 <Option value={7}>7</Option>
                                 <Option value={15}>15</Option>
                              </Select>
                           </Form.Item>
                        </>
                     )}

                     <Form.Item
                        name='MemType'
                        label={
                           lang === 'TH'
                              ? 'ประเภทบุคคล'
                              : lang === 'ENG'
                              ? 'Person Type'
                              : lang === 'KH'
                              ? 'ប្រភេទមនុស្ស'
                              : 'Person Type'
                        }
                        rules={[
                           {
                              required: true,

                              message: 'ประเภทบุคคล',
                           },
                        ]}
                     >
                        <Select
                           placeholder={
                              lang === 'TH'
                                 ? 'ประเภทบุคคล'
                                 : lang === 'ENG'
                                 ? 'Person Type'
                                 : lang === 'KH'
                                 ? 'ប្រភេទមនុស្ស'
                                 : 'Person Type'
                           }
                           onChange={(value) => {
                              if (value === 'MB') {
                                 setIsThai(true);
                                 setIsForeige(false);
                       
                              } else if (value === 'F') {
                                 setIsForeige(true);
                                 setIsThai(false);
                              
                              } 
                           }}
                        >
                           <Option value='MB'>
                              {lang === 'TH'
                                 ? 'คนไทย'
                                 : lang === 'ENG'
                                 ? 'Thai'
                                 : lang === 'KH'
                                 ? 'ថៃ'
                                 : 'Thai'}
                           </Option>
                           <Option value='F'>
                              {lang === 'TH'
                                 ? 'ต่างชาติ'
                                 : lang === 'ENG'
                                 ? 'Foreigner'
                                 : lang === 'KH'
                                 ? 'បរទេស'
                                 : 'Foreigner'}
                           </Option>
                        
                        </Select>
                     </Form.Item>

                     {packageSelected && (
                        <>
                           <div>
                              <h5>
                                 <Form.Item label='ใช้ PV '>
                                    <span className='text-danger'>
                                       <>
                                          <b>
                                             {' '}
                                             {packageSelected === 'Tester'
                                                ? 25
                                                : packageSelected === 'Member'
                                                ? 250
                                                : packageSelected === 'Holder'
                                                ? qtx * 500
                                                : 0}
                                          </b>
                                       </>
                                    </span>{' '}
                                 </Form.Item>
                              </h5>
                           </div>

                           <br />

                           {(Number(qtx) === Number(totalQty) &&
                              recommendedBy &&
                              isThai) ||
                           isForeiger ? (
                              <>
                                 {isThai ? (
                                    <>
                                       {userFormCardId.userId ? (
                                          <>
                                             <Form.Item label='ชื่อแสดง'>
                                                <Input
                                                   style={{
                                                      color: 'black',
                                                   }}
                                                   value={userFormCardId.name}
                                                   disabled
                                                />
                                             </Form.Item>
                                          </>
                                       ) : (
                                          <Form.Item
                                             name='username'
                                             label='ชื่อที่ใช้แสดง'
                                          >
                                             <Input placeholder='ชื่อที่ใช้แสดง' />
                                          </Form.Item>
                                       )}

                                       <Form.Item
                                          rules={[
                                             {
                                                pattern: /^(?:\d*)$/,
                                                message:
                                                   'เลขบัตรประชาชนต้องเป็นตัวเลขเท่านั้น',
                                             },
                                             {
                                                pattern: /^[\d]{0,13}$/,
                                                message:
                                                   'กรุณาพิมให้ครบ 13 หลัก',
                                             },
                                          ]}
                                          name='cardId'
                                          label='เลขบัตรประชาชน'
                                       >
                                          <Input
                                             maxLength={13}
                                             value={cardId}
                                             rules={[{ type: 'number' }]}
                                             onChange={(e) => {
                                                setCardId(e.target.value);
                                             }}
                                             placeholder='X-XXXX-XXXXX-XX-X'
                                          />
                                          {thaiId.verify(cardId) === true ? (
                                             <p className='text-success'>
                                                {' '}
                                                หมายเลขบัตรประชาชนถูกต้อง{' '}
                                             </p>
                                          ) : (
                                             <p className='text-danger'>
                                                {' '}
                                                หมายเลขบัตรประชาชนไม่ถูกต้อง{' '}
                                             </p>
                                          )}
                                       </Form.Item>
                                    </>
                                 ) : (
                                    <>
                                       <Form.Item
                                          name='username'
                                          label='ชื่อที่ใช้แสดง'
                                          rules={[
                                             {
                                                required: true,
                                                message: 'ชื่อที่ใช้แสดง',
                                             },
                                          ]}
                                       >
                                          <Input placeholder='ชื่อที่ใช้แสดง' />
                                       </Form.Item>
                                       <Form.Item
                                          rules={[
                                             {
                                                required: true,
                                                message: 'passport',
                                             },
                                          ]}
                                          name='passport'
                                          label='พาสปอร์ต'
                                       >
                                          <Input placeholder='พาสปอร์ต' />
                                       </Form.Item>
                                    </>
                                 )}
                                 {isThai ? (
                                    <>
                                       {thaiId.verify(cardId) === true && (
                                          <>
                                             {userFormCardId.userId ? (
                                                <>
                                                   <span
                                                      style={{
                                                         fontSize: '18px',
                                                      }}
                                                   >
                                                      <b>ข้อมูลบัตรประชาชน</b>
                                                   </span>
                                                   <Form.Item label='ชื่อ'>
                                                      <Input
                                                         style={{
                                                            color: 'black',
                                                         }}
                                                         value={
                                                            userFormCardId.firstName
                                                         }
                                                         disabled
                                                      />
                                                   </Form.Item>
                                                   <Form.Item label='นามสกุล'>
                                                      <Input
                                                         style={{
                                                            color: 'black',
                                                         }}
                                                         value={
                                                            userFormCardId.lastName
                                                         }
                                                         disabled
                                                      />
                                                   </Form.Item>

                                                   <Form.Item label='ที่อยู่บัตรประชาชน'>
                                                      <Input
                                                         style={{
                                                            color: 'black',
                                                         }}
                                                         value={
                                                            userFormCardId.address
                                                         }
                                                         disabled
                                                      />
                                                   </Form.Item>
                                                   <div>
                                                      <span
                                                         style={{
                                                            fontSize: '18px',
                                                         }}
                                                      >
                                                         <b className=''>
                                                            ที่อยู่จัดส่งสินค้า
                                                         </b>
                                                      </span>
                                                      <div>
                                                         <Form.Item label='จังหวัด'>
                                                            <Input
                                                               style={{
                                                                  color: 'black',
                                                               }}
                                                               value={
                                                                  userFormCardId.province
                                                               }
                                                               disabled
                                                            />
                                                         </Form.Item>

                                                         <Form.Item label='อำเภอ'>
                                                            <Input
                                                               style={{
                                                                  color: 'black',
                                                               }}
                                                               value={
                                                                  userFormCardId.district
                                                               }
                                                               disabled
                                                            />
                                                         </Form.Item>
                                                         <Form.Item label='ตำบล'>
                                                            <Input
                                                               style={{
                                                                  color: 'black',
                                                               }}
                                                               value={
                                                                  userFormCardId.subdistrict
                                                               }
                                                               disabled
                                                            />
                                                         </Form.Item>
                                                         <Form.Item label='หมายเลขไปรษณี'>
                                                            <Input
                                                               style={{
                                                                  color: 'black',
                                                               }}
                                                               value={
                                                                  userFormCardId.postalCode
                                                               }
                                                               disabled
                                                            />
                                                         </Form.Item>
                                                      </div>
                                                   </div>
                                                   <div>
                                                      <span
                                                         style={{
                                                            fontSize: '18px',
                                                         }}
                                                      >
                                                         {' '}
                                                         <b>
                                                            {' '}
                                                            ข้อมูลการติดต่อ{' '}
                                                         </b>
                                                      </span>
                                                      <Form.Item label='Email'>
                                                         <Input
                                                            style={{
                                                               color: 'black',
                                                            }}
                                                            value={
                                                               userFormCardId.email
                                                            }
                                                            disabled
                                                         />
                                                      </Form.Item>

                                                      <Form.Item label='เบอร์มือถือ'>
                                                         <Input
                                                            style={{
                                                               color: 'black',
                                                            }}
                                                            value={
                                                               userFormCardId.tel
                                                            }
                                                            disabled
                                                         />
                                                      </Form.Item>
                                                   </div>
                                                   <div>
                                                      <span
                                                         style={{
                                                            fontSize: '18px',
                                                         }}
                                                      >
                                                         <b>
                                                            {' '}
                                                            ข้อมูลการรับโบนัส{' '}
                                                         </b>
                                                      </span>
                                                      <Form.Item label='กรุณาเลือกธนาคาร'>
                                                         <Input
                                                            style={{
                                                               color: 'black',
                                                            }}
                                                            value={
                                                               userFormCardId.bank
                                                            }
                                                            disabled
                                                         />
                                                      </Form.Item>

                                                      {value4 ===
                                                         'ธนาคารออมสิน' ||
                                                      value4 ===
                                                         'ธนาคารอาคารสงเคราะห์' ||
                                                      value4 ===
                                                         'ธนาคารเพื่อการเกษตรและสหกรณ์' ? (
                                                         <Form.Item label='เลขที่บัญชี'>
                                                            <Input
                                                               style={{
                                                                  color: 'black',
                                                               }}
                                                               value={
                                                                  userFormCardId.bankAccount
                                                               }
                                                               disabled
                                                            />
                                                         </Form.Item>
                                                      ) : (
                                                         <Form.Item label='เลขที่บัญชี'>
                                                            <Input
                                                               style={{
                                                                  color: 'black',
                                                               }}
                                                               value={
                                                                  userFormCardId.bankAccount
                                                               }
                                                               disabled
                                                            />
                                                         </Form.Item>
                                                      )}
                                                   </div>
                                                   <div>
                                                      <span
                                                         style={{
                                                            fontSize: '18px',
                                                            paddingTop: '10px',
                                                            paddingBottom:
                                                               '10px',
                                                         }}
                                                      >
                                                         <b>ผู้รับผลประโยชน์</b>
                                                      </span>
                                                      <Form.Item label='ผู้รับผลประโยชน์'>
                                                         <Input
                                                            style={{
                                                               color: 'black',
                                                            }}
                                                            value={
                                                               userFormCardId.beneficiary
                                                            }
                                                            disabled
                                                         />
                                                      </Form.Item>
                                                      <Form.Item label='ความสัมพันธ์'>
                                                         <Input
                                                            style={{
                                                               color: 'black',
                                                            }}
                                                            value={
                                                               userFormCardId.relationship
                                                            }
                                                            disabled
                                                         />
                                                      </Form.Item>
                                                   </div>
                                                   <Form.Item className='d-flex justify-content-center  pt-5 pb-5'>
                                                      <Button
                                                         block
                                                         type='primary'
                                                         htmlType='submit'
                                                      >
                                                         ยืนยัน
                                                      </Button>
                                                   </Form.Item>
                                                </>
                                             ) : (
                                                <>
                                                   <span
                                                      style={{
                                                         fontSize: '18px',
                                                      }}
                                                   >
                                                      <b>ข้อมูลบัตรประชาชน</b>
                                                   </span>
                                                   <Form.Item
                                                      name='gender'
                                                      label='เพศ'
                                                   >
                                                      <Radio.Group
                                                         value={value2}
                                                         onChange={onChange2}
                                                         options={options}
                                                      />
                                                   </Form.Item>
                                                   <Form.Item
                                                      label='คำนำหน้า'
                                                      name='gendername'
                                                   >
                                                      <Radio.Group
                                                         value={value3}
                                                         onChange={onChange3}
                                                      >
                                                         <Radio value='นาย'>
                                                            นาย
                                                         </Radio>
                                                         <Radio value='นาง'>
                                                            นาง
                                                         </Radio>
                                                         <Radio value='นางสาว'>
                                                            นางสาว
                                                         </Radio>
                                                      </Radio.Group>
                                                   </Form.Item>
                                                   <Form.Item
                                                      rules={[
                                                         {
                                                            required: true,
                                                            message: 'ชื่อ',
                                                         },
                                                      ]}
                                                      name='firstName'
                                                      label='ชื่อ'
                                                   >
                                                      <Input placeholder='ชื่อ' />
                                                   </Form.Item>
                                                   <Form.Item
                                                      rules={[
                                                         {
                                                            required: true,
                                                            message: 'นามสกุล',
                                                         },
                                                      ]}
                                                      name='lastName'
                                                      label='นามสกุล'
                                                   >
                                                      <Input placeholder='นามสกุล' />
                                                   </Form.Item>

                                                   <Form.Item
                                                      name='address'
                                                      label='ที่อยู่บัตรประชาชน'
                                                   >
                                                      <TextArea
                                                         type='text'
                                                         placeholder='บ้านเลขที่ ซอย หมู่บ้าน ถนน'
                                                      />
                                                   </Form.Item>
                                                   <div>
                                                      <span
                                                         style={{
                                                            fontSize: '18px',
                                                         }}
                                                      >
                                                         <b className=''>
                                                            ที่อยู่จัดส่งสินค้า
                                                         </b>
                                                      </span>

                                                      <ThailandAddressTypeahead
                                                         value={val}
                                                         onValueChange={(val) =>
                                                            setVal(val)
                                                         }
                                                      >
                                                         <div className='mt-3'>
                                                            <TextAddress>
                                                               {' '}
                                                               <ThailandAddressTypeahead.ProvinceInput placeholder='จังหวัด' />
                                                            </TextAddress>
                                                            <TextAddress>
                                                               <ThailandAddressTypeahead.DistrictInput placeholder='อำเภอ' />
                                                            </TextAddress>
                                                            <TextAddress>
                                                               <ThailandAddressTypeahead.SubdistrictInput placeholder='ตำบล' />
                                                            </TextAddress>

                                                            <TextAddress>
                                                               {' '}
                                                               <ThailandAddressTypeahead.PostalCodeInput placeholder='รหัสไปรษณีย์' />
                                                            </TextAddress>
                                                         </div>

                                                         {/** you can put any customizable layout like below */}

                                                         <ThailandAddressTypeahead.Suggestion />
                                                         {/** or custom our own suggestion with CustomSuggestion */}
                                                      </ThailandAddressTypeahead>
                                                      <Form.Item
                                                         name='zipcode'
                                                         label='รหัสไปรษณีย์'
                                                      >
                                                         <Input placeholder='รหัสไปรษณีย์' />
                                                      </Form.Item>
                                                   </div>
                                                   <div>
                                                      <span
                                                         style={{
                                                            fontSize: '18px',
                                                         }}
                                                      >
                                                         {' '}
                                                         <b>
                                                            {' '}
                                                            ข้อมูลการติดต่อ{' '}
                                                         </b>
                                                      </span>
                                                      <Form.Item
                                                         name='email'
                                                         label='Email'
                                                      >
                                                         <Input
                                                            type='email'
                                                            placeholder='Email'
                                                         />
                                                      </Form.Item>

                                                      <Form.Item
                                                         name='tel'
                                                         label='เบอร์มือถือ'
                                                      >
                                                         <Input placeholder='เบอร์มือถือ' />
                                                      </Form.Item>
                                                   </div>
                                                   <div>
                                                      <span
                                                         style={{
                                                            fontSize: '18px',
                                                         }}
                                                      >
                                                         <b>
                                                            {' '}
                                                            ข้อมูลการรับโบนัส{' '}
                                                         </b>
                                                      </span>
                                                      <Form.Item
                                                         name='bank'
                                                         label='กรุณาเลือกธนาคาร'
                                                         rules={[
                                                            {
                                                               required: true,
                                                               message:
                                                                  'กรุณาเลือกธนาคาร',
                                                            },
                                                         ]}
                                                      >
                                                         <Select
                                                            onChange={(value) =>
                                                               setValue4(value)
                                                            }
                                                            placeholder='กรุณาเลือกธนาคาร'
                                                            allowClear
                                                            style={{
                                                               width: '100%',
                                                               fontSize:
                                                                  '24px !important',
                                                            }}
                                                         >
                                                            <Option
                                                               key={1}
                                                               value='ธนาคารกรุงเทพ'
                                                            >
                                                               <div className='d-flex justify-content-between'>
                                                                  {' '}
                                                                  ธนาคารกรุงเทพ
                                                                  <Image
                                                                     style={{
                                                                        backgroundColor:
                                                                           '#1e4598',
                                                                     }}
                                                                     src={BBL}
                                                                     width={25}
                                                                     preview={
                                                                        false
                                                                     }
                                                                  />
                                                               </div>
                                                            </Option>
                                                            <Option
                                                               key={2}
                                                               value='ธนาคารกสิกรไทย'
                                                            >
                                                               <div className='d-flex justify-content-between'>
                                                                  ธนาคารกสิกรไทย
                                                                  <Image
                                                                     style={{
                                                                        backgroundColor:
                                                                           '#138f2d',
                                                                     }}
                                                                     src={KBANK}
                                                                     width={25}
                                                                     preview={
                                                                        false
                                                                     }
                                                                  />
                                                               </div>
                                                            </Option>
                                                            <Option
                                                               key={3}
                                                               value='ธนาคารกรุงไทย'
                                                            >
                                                               <div className='d-flex justify-content-between'>
                                                                  ธนาคารกรุงไทย
                                                                  <Image
                                                                     style={{
                                                                        backgroundColor:
                                                                           '#1ba5e1',
                                                                     }}
                                                                     src={KTB}
                                                                     width={25}
                                                                     preview={
                                                                        false
                                                                     }
                                                                  />
                                                               </div>
                                                            </Option>
                                                            <Option
                                                               key={4}
                                                               value='ธนาคารเพื่อการเกษตรและสหกรณ์'
                                                            >
                                                               <div className='d-flex justify-content-between'>
                                                                  ธนาคารเพื่อการเกษตรและสหกรณ์
                                                                  <Image
                                                                     style={{
                                                                        backgroundColor:
                                                                           '#4b9b1d',
                                                                     }}
                                                                     src={BAAC}
                                                                     width={25}
                                                                     preview={
                                                                        false
                                                                     }
                                                                  />
                                                               </div>
                                                            </Option>
                                                            <Option
                                                               key={5}
                                                               value='ธนาคารกรุงศรีอยุธยา'
                                                            >
                                                               <div className='d-flex justify-content-between'>
                                                                  ธนาคารกรุงศรีอยุธยา
                                                                  <Image
                                                                     style={{
                                                                        backgroundColor:
                                                                           '#fec43b',
                                                                     }}
                                                                     src={BAY}
                                                                     width={25}
                                                                     preview={
                                                                        false
                                                                     }
                                                                  />
                                                               </div>
                                                            </Option>
                                                            <Option
                                                               key={6}
                                                               value='ธนาคารออมสิน'
                                                            >
                                                               <div className='d-flex justify-content-between'>
                                                                  ธนาคารออมสิน
                                                                  <Image
                                                                     style={{
                                                                        backgroundColor:
                                                                           '#eb198d',
                                                                     }}
                                                                     src={GSB}
                                                                     width={25}
                                                                     preview={
                                                                        false
                                                                     }
                                                                  />
                                                               </div>
                                                            </Option>

                                                            <Option
                                                               key={8}
                                                               value='ธนาคารไทยพาณิชย์'
                                                            >
                                                               <div className='d-flex justify-content-between'>
                                                                  ธนาคารไทยพาณิชย์
                                                                  <Image
                                                                     style={{
                                                                        backgroundColor:
                                                                           '#4e2e7f',
                                                                     }}
                                                                     src={SCB}
                                                                     width={25}
                                                                     preview={
                                                                        false
                                                                     }
                                                                  />
                                                               </div>
                                                            </Option>
                                                            <Option
                                                               key={9}
                                                               value='ธนาคารทหารไทยธนชาติ'
                                                            >
                                                               <div className='d-flex justify-content-between'>
                                                                  ธนาคารทหารไทยธนชาติ
                                                                  <Image
                                                                     style={{
                                                                        backgroundColor:
                                                                           '#fff',
                                                                     }}
                                                                     src={TTB}
                                                                     width={25}
                                                                     preview={
                                                                        false
                                                                     }
                                                                  />
                                                               </div>
                                                            </Option>
                                                            <Option
                                                               key={10}
                                                               value='ธนาคารทิสโก้'
                                                            >
                                                               <div className='d-flex justify-content-between'>
                                                                  ธนาคารทิสโก้
                                                                  <Image
                                                                     style={{
                                                                        backgroundColor:
                                                                           '#12549f',
                                                                     }}
                                                                     src={TISCO}
                                                                     width={25}
                                                                     preview={
                                                                        false
                                                                     }
                                                                  />
                                                               </div>
                                                            </Option>

                                                            <Option
                                                               key={12}
                                                               value='ธนาคารยูโอบี'
                                                            >
                                                               <div className='d-flex justify-content-between'>
                                                                  ธนาคารยูโอบี
                                                                  <Image
                                                                     style={{
                                                                        backgroundColor:
                                                                           '#0b3979',
                                                                     }}
                                                                     src={UOB}
                                                                     width={25}
                                                                     preview={
                                                                        false
                                                                     }
                                                                  />
                                                               </div>
                                                            </Option>
                                                            <Option
                                                               key={13}
                                                               value='ธนาคารแลนด์ แอนด์ เฮาส์'
                                                            >
                                                               <div className='d-flex justify-content-between'>
                                                                  ธนาคารแลนด์
                                                                  แอนด์ เฮาส์
                                                                  <Image
                                                                     style={{
                                                                        backgroundColor:
                                                                           '#6d6e71',
                                                                     }}
                                                                     src={LHB}
                                                                     width={25}
                                                                     preview={
                                                                        false
                                                                     }
                                                                  />
                                                               </div>
                                                            </Option>
                                                            <Option
                                                               key={14}
                                                               value='ธนาคารอิสลามแห่งประเทศไทย'
                                                            >
                                                               <div className='d-flex justify-content-between'>
                                                                  ธนาคารอิสลามแห่งประเทศไทย
                                                                  <Image
                                                                     style={{
                                                                        backgroundColor:
                                                                           '#184615',
                                                                     }}
                                                                     src={IBANK}
                                                                     width={25}
                                                                     preview={
                                                                        false
                                                                     }
                                                                  />
                                                               </div>
                                                            </Option>
                                                            <Option
                                                               key={15}
                                                               value='ธนาคารอาคารสงเคราะห์'
                                                            >
                                                               <div className='d-flex justify-content-between'>
                                                                  ธนาคารอาคารสงเคราะห์
                                                                  <Image
                                                                     style={{
                                                                        backgroundColor:
                                                                           '#f57d23',
                                                                     }}
                                                                     src={GHB}
                                                                     width={25}
                                                                     preview={
                                                                        false
                                                                     }
                                                                  />
                                                               </div>
                                                            </Option>
                                                         </Select>
                                                      </Form.Item>

                                                      {value4 ===
                                                         'ธนาคารออมสิน' ||
                                                      value4 ===
                                                         'ธนาคารอาคารสงเคราะห์' ||
                                                      value4 ===
                                                         'ธนาคารเพื่อการเกษตรและสหกรณ์' ? (
                                                         <Form.Item
                                                            rules={[
                                                               {
                                                                  required: true,
                                                                  message:
                                                                     'กรุณากรอกเลขที่บัญชี',
                                                               },
                                                            ]}
                                                            name='bankAccount'
                                                            label='เลขที่บัญชี'
                                                         >
                                                            <Input
                                                               maxLength={12}
                                                               placeholder='เลขที่บัญชี'
                                                            />
                                                         </Form.Item>
                                                      ) : (
                                                         <Form.Item
                                                            rules={[
                                                               {
                                                                  required: true,
                                                                  message:
                                                                     'กรุณากรอกเลขที่บัญชี',
                                                               },
                                                            ]}
                                                            name='bankAccount'
                                                            label='เลขที่บัญชี'
                                                         >
                                                            <Input
                                                               maxLength={10}
                                                               placeholder='เลขที่บัญชี'
                                                            />
                                                         </Form.Item>
                                                      )}

                                                      <Form.Item
                                                         name='bankBranch'
                                                         label='สาขาธนาคาร'
                                                      >
                                                         <Input placeholder='สาขาธนาคาร' />
                                                      </Form.Item>
                                                   </div>
                                                   <div>
                                                      <span
                                                         style={{
                                                            fontSize: '18px',
                                                            paddingTop: '10px',
                                                            paddingBottom:
                                                               '10px',
                                                         }}
                                                      >
                                                         <b>ผู้รับผลประโยชน์</b>
                                                      </span>
                                                      <Form.Item
                                                         name='beneficiary'
                                                         label='ผู้รับผลประโยชน์'
                                                      >
                                                         <Input />
                                                      </Form.Item>
                                                      <Form.Item
                                                         name='relationship'
                                                         label='ความสัมพันธ์'
                                                      >
                                                         <Input />
                                                      </Form.Item>
                                                   </div>
                                                   <Form.Item className='d-flex justify-content-center  pt-5 pb-5'>
                                                      <Button
                                                         block
                                                         type='primary'
                                                         htmlType='submit'
                                                      >
                                                         ยืนยัน
                                                      </Button>
                                                   </Form.Item>
                                                </>
                                             )}
                                          </>
                                       )}
                                    </>
                                 ) : isForeiger ? (
                                    <>
                                       <span style={{ fontSize: '18px' }}>
                                          <b>ข้อมูลบัตรประชาชน</b>
                                       </span>
                                       <Form.Item name='gender' label='เพศ'>
                                          <Radio.Group
                                             value={value2}
                                             onChange={onChange2}
                                             options={options}
                                          />
                                       </Form.Item>
                                       <Form.Item
                                          label='คำนำหน้า'
                                          name='gendername'
                                       >
                                          <Radio.Group
                                             value={value3}
                                             onChange={onChange3}
                                          >
                                             <Radio value='นาย'>นาย</Radio>
                                             <Radio value='นาง'>นาง</Radio>
                                             <Radio value='นางสาว'>
                                                นางสาว
                                             </Radio>
                                          </Radio.Group>
                                       </Form.Item>
                                       <Form.Item
                                          rules={[
                                             {
                                                required: true,
                                                message: 'ชื่อ',
                                             },
                                          ]}
                                          name='firstName'
                                          label='ชื่อ'
                                       >
                                          <Input placeholder='ชื่อ' />
                                       </Form.Item>
                                       <Form.Item
                                          rules={[
                                             {
                                                required: true,
                                                message: 'นามสกุล',
                                             },
                                          ]}
                                          name='lastName'
                                          label='นามสกุล'
                                       >
                                          <Input placeholder='นามสกุล' />
                                       </Form.Item>

                                       <Form.Item
                                          name='address'
                                          label='ที่อยู่บัตรประชาชน'
                                       >
                                          <TextArea
                                             type='text'
                                             placeholder='บ้านเลขที่ ซอย หมู่บ้าน ถนน'
                                          />
                                       </Form.Item>
                                       <div>
                                          <span style={{ fontSize: '18px' }}>
                                             <b className=''>
                                                ที่อยู่จัดส่งสินค้า
                                             </b>
                                          </span>

                                          <ThailandAddressTypeahead
                                             value={val}
                                             onValueChange={(val) =>
                                                setVal(val)
                                             }
                                          >
                                             <div className='mt-3'>
                                                <TextAddress>
                                                   {' '}
                                                   <ThailandAddressTypeahead.ProvinceInput placeholder='จังหวัด' />
                                                </TextAddress>
                                                <TextAddress>
                                                   <ThailandAddressTypeahead.DistrictInput placeholder='อำเภอ' />
                                                </TextAddress>
                                                <TextAddress>
                                                   <ThailandAddressTypeahead.SubdistrictInput placeholder='ตำบล' />
                                                </TextAddress>

                                                <TextAddress>
                                                   {' '}
                                                   <ThailandAddressTypeahead.PostalCodeInput placeholder='รหัสไปรษณีย์' />
                                                </TextAddress>
                                             </div>

                                             {/** you can put any customizable layout like below */}

                                             <ThailandAddressTypeahead.Suggestion />
                                             {/** or custom our own suggestion with CustomSuggestion */}
                                          </ThailandAddressTypeahead>
                                          <Form.Item
                                             name='zipcode'
                                             label='รหัสไปรษณีย์'
                                          >
                                             <Input placeholder='รหัสไปรษณีย์' />
                                          </Form.Item>
                                       </div>
                                       <div>
                                          <span style={{ fontSize: '18px' }}>
                                             {' '}
                                             <b> ข้อมูลการติดต่อ </b>
                                          </span>
                                          <Form.Item name='email' label='Email'>
                                             <Input
                                                type='email'
                                                placeholder='Email'
                                             />
                                          </Form.Item>

                                          <Form.Item
                                             name='tel'
                                             label='เบอร์มือถือ'
                                          >
                                             <Input placeholder='เบอร์มือถือ' />
                                          </Form.Item>
                                       </div>
                                       <div>
                                          <span style={{ fontSize: '18px' }}>
                                             <b> ข้อมูลการรับโบนัส </b>
                                          </span>
                                          <Form.Item
                                             name='bank'
                                             label='กรุณาเลือกธนาคาร'
                                             rules={[
                                                {
                                                   required: true,
                                                   message: 'กรุณาเลือกธนาคาร',
                                                },
                                             ]}
                                          >
                                             <Select
                                                onChange={(value) =>
                                                   setValue4(value)
                                                }
                                                placeholder='กรุณาเลือกธนาคาร'
                                                allowClear
                                                style={{
                                                   width: '100%',
                                                   fontSize: '24px !important',
                                                }}
                                             >
                                                <Option
                                                   key={1}
                                                   value='ธนาคารกรุงเทพ'
                                                >
                                                   <div className='d-flex justify-content-between'>
                                                      {' '}
                                                      ธนาคารกรุงเทพ
                                                      <Image
                                                         style={{
                                                            backgroundColor:
                                                               '#1e4598',
                                                         }}
                                                         src={BBL}
                                                         width={25}
                                                         preview={false}
                                                      />
                                                   </div>
                                                </Option>
                                                <Option
                                                   key={2}
                                                   value='ธนาคารกสิกรไทย'
                                                >
                                                   <div className='d-flex justify-content-between'>
                                                      ธนาคารกสิกรไทย
                                                      <Image
                                                         style={{
                                                            backgroundColor:
                                                               '#138f2d',
                                                         }}
                                                         src={KBANK}
                                                         width={25}
                                                         preview={false}
                                                      />
                                                   </div>
                                                </Option>
                                                <Option
                                                   key={3}
                                                   value='ธนาคารกรุงไทย'
                                                >
                                                   <div className='d-flex justify-content-between'>
                                                      ธนาคารกรุงไทย
                                                      <Image
                                                         style={{
                                                            backgroundColor:
                                                               '#1ba5e1',
                                                         }}
                                                         src={KTB}
                                                         width={25}
                                                         preview={false}
                                                      />
                                                   </div>
                                                </Option>
                                                <Option
                                                   key={4}
                                                   value='ธนาคารเพื่อการเกษตรและสหกรณ์'
                                                >
                                                   <div className='d-flex justify-content-between'>
                                                      ธนาคารเพื่อการเกษตรและสหกรณ์
                                                      <Image
                                                         style={{
                                                            backgroundColor:
                                                               '#4b9b1d',
                                                         }}
                                                         src={BAAC}
                                                         width={25}
                                                         preview={false}
                                                      />
                                                   </div>
                                                </Option>
                                                <Option
                                                   key={5}
                                                   value='ธนาคารกรุงศรีอยุธยา'
                                                >
                                                   <div className='d-flex justify-content-between'>
                                                      ธนาคารกรุงศรีอยุธยา
                                                      <Image
                                                         style={{
                                                            backgroundColor:
                                                               '#fec43b',
                                                         }}
                                                         src={BAY}
                                                         width={25}
                                                         preview={false}
                                                      />
                                                   </div>
                                                </Option>
                                                <Option
                                                   key={6}
                                                   value='ธนาคารออมสิน'
                                                >
                                                   <div className='d-flex justify-content-between'>
                                                      ธนาคารออมสิน
                                                      <Image
                                                         style={{
                                                            backgroundColor:
                                                               '#eb198d',
                                                         }}
                                                         src={GSB}
                                                         width={25}
                                                         preview={false}
                                                      />
                                                   </div>
                                                </Option>

                                                <Option
                                                   key={8}
                                                   value='ธนาคารไทยพาณิชย์'
                                                >
                                                   <div className='d-flex justify-content-between'>
                                                      ธนาคารไทยพาณิชย์
                                                      <Image
                                                         style={{
                                                            backgroundColor:
                                                               '#4e2e7f',
                                                         }}
                                                         src={SCB}
                                                         width={25}
                                                         preview={false}
                                                      />
                                                   </div>
                                                </Option>
                                                <Option
                                                   key={9}
                                                   value='ธนาคารทหารไทยธนชาติ'
                                                >
                                                   <div className='d-flex justify-content-between'>
                                                      ธนาคารทหารไทยธนชาติ
                                                      <Image
                                                         style={{
                                                            backgroundColor:
                                                               '#fff',
                                                         }}
                                                         src={TTB}
                                                         width={25}
                                                         preview={false}
                                                      />
                                                   </div>
                                                </Option>
                                                <Option
                                                   key={10}
                                                   value='ธนาคารทิสโก้'
                                                >
                                                   <div className='d-flex justify-content-between'>
                                                      ธนาคารทิสโก้
                                                      <Image
                                                         style={{
                                                            backgroundColor:
                                                               '#12549f',
                                                         }}
                                                         src={TISCO}
                                                         width={25}
                                                         preview={false}
                                                      />
                                                   </div>
                                                </Option>

                                                <Option
                                                   key={12}
                                                   value='ธนาคารยูโอบี'
                                                >
                                                   <div className='d-flex justify-content-between'>
                                                      ธนาคารยูโอบี
                                                      <Image
                                                         style={{
                                                            backgroundColor:
                                                               '#0b3979',
                                                         }}
                                                         src={UOB}
                                                         width={25}
                                                         preview={false}
                                                      />
                                                   </div>
                                                </Option>
                                                <Option
                                                   key={13}
                                                   value='ธนาคารแลนด์ แอนด์ เฮาส์'
                                                >
                                                   <div className='d-flex justify-content-between'>
                                                      ธนาคารแลนด์ แอนด์ เฮาส์
                                                      <Image
                                                         style={{
                                                            backgroundColor:
                                                               '#6d6e71',
                                                         }}
                                                         src={LHB}
                                                         width={25}
                                                         preview={false}
                                                      />
                                                   </div>
                                                </Option>
                                                <Option
                                                   key={14}
                                                   value='ธนาคารอิสลามแห่งประเทศไทย'
                                                >
                                                   <div className='d-flex justify-content-between'>
                                                      ธนาคารอิสลามแห่งประเทศไทย
                                                      <Image
                                                         style={{
                                                            backgroundColor:
                                                               '#184615',
                                                         }}
                                                         src={IBANK}
                                                         width={25}
                                                         preview={false}
                                                      />
                                                   </div>
                                                </Option>
                                                <Option
                                                   key={15}
                                                   value='ธนาคารอาคารสงเคราะห์'
                                                >
                                                   <div className='d-flex justify-content-between'>
                                                      ธนาคารอาคารสงเคราะห์
                                                      <Image
                                                         style={{
                                                            backgroundColor:
                                                               '#f57d23',
                                                         }}
                                                         src={GHB}
                                                         width={25}
                                                         preview={false}
                                                      />
                                                   </div>
                                                </Option>
                                             </Select>
                                          </Form.Item>

                                          {value4 === 'ธนาคารออมสิน' ||
                                          value4 === 'ธนาคารอาคารสงเคราะห์' ||
                                          value4 ===
                                             'ธนาคารเพื่อการเกษตรและสหกรณ์' ? (
                                             <Form.Item
                                                rules={[
                                                   {
                                                      required: true,
                                                      message:
                                                         'กรุณากรอกเลขที่บัญชี',
                                                   },
                                                ]}
                                                name='bankAccount'
                                                label='เลขที่บัญชี'
                                             >
                                                <Input
                                                   maxLength={12}
                                                   placeholder='เลขที่บัญชี'
                                                />
                                             </Form.Item>
                                          ) : (
                                             <Form.Item
                                                rules={[
                                                   {
                                                      required: true,
                                                      message:
                                                         'กรุณากรอกเลขที่บัญชี',
                                                   },
                                                ]}
                                                name='bankAccount'
                                                label='เลขที่บัญชี'
                                             >
                                                <Input
                                                   maxLength={10}
                                                   placeholder='เลขที่บัญชี'
                                                />
                                             </Form.Item>
                                          )}

                                          <Form.Item
                                             name='bankBranch'
                                             label='สาขาธนาคาร'
                                          >
                                             <Input placeholder='สาขาธนาคาร' />
                                          </Form.Item>
                                       </div>
                                       <div>
                                          <span
                                             style={{
                                                fontSize: '18px',
                                                paddingTop: '10px',
                                                paddingBottom: '10px',
                                             }}
                                          >
                                             <b>ผู้รับผลประโยชน์</b>
                                          </span>
                                          <Form.Item
                                             name='beneficiary'
                                             label='ผู้รับผลประโยชน์'
                                          >
                                             <Input />
                                          </Form.Item>
                                          <Form.Item
                                             name='relationship'
                                             label='ความสัมพันธ์'
                                          >
                                             <Input />
                                          </Form.Item>
                                       </div>
                                       <Form.Item className='d-flex justify-content-center  pt-5 pb-5'>
                                          <Button
                                             block
                                             type='primary'
                                             htmlType='submit'
                                          >
                                             ยืนยัน
                                          </Button>
                                       </Form.Item>
                                    </>
                                 ) : null}

                                 <div>
                                    <span style={{ fontSize: '18px' }}>
                                       <b>ข้อตกลงในการสมัคร</b>
                                    </span>
                                    <div className='register__permission'>
                                       <Permission />
                                    </div>
                                 </div>
                              </>
                           ) : (
                              <>{null}</>
                           )}
                        </>
                     )}
                  </Form>
                  {isModalOpen && (
                     <Modal
                        visible={isModalOpen}
                        footer={false}
                        onCancel={() => {
                           setIsModalOpen(false);
                           isCheck = true;
                           localStorage.removeItem('recomId');
                           localStorage.removeItem('recomName');
                           window.location.reload();
                        }}
                     >
                        <Result
                           status='error'
                           title='บริษัทสยามไพรพลัส จำกัด'
                           subTitle={`รหัส ${userUplineId} และ ${recommendedBy} 
                        ไม่ได้อยู่สายงานเดียวกัน!!!`}
                           extra={[
                              <Button
                                 className='btn-danger'
                                 onClick={() => {
                                    setIsModalOpen(false);
                                    isCheck = true;
                                    localStorage.removeItem('recomId');
                                    localStorage.removeItem('recomName');
                                    window.location.reload();
                                 }}
                              >
                                 {' '}
                                 OK{' '}
                              </Button>,
                           ]}
                        ></Result>
                     </Modal>
                  )}
                  {modalError && (
                     <Modal
                        visible={modalError}
                        footer={false}
                        onCancel={() => {
                           setModalError(false);
                           localStorage.removeItem('recomId');
                           window.location.reload();
                        }}
                     >
                        <Result
                           status='error'
                           title={
                              <>
                                 <p> {message}</p>
                                 <p style={{ fontSize: '18px' }}></p>
                              </>
                           }
                           extra={[
                              <Button
                                 className='btn-danger'
                                 onClick={() => {
                                    setModalError(false);
                                    localStorage.removeItem('recomId');
                                    window.location.reload();
                                 }}
                              >
                                 {' '}
                                 OK{' '}
                              </Button>,
                           ]}
                        ></Result>
                     </Modal>
                  )}
                  {modalError2 && (
                     <Modal
                        visible={modalError2}
                        footer={false}
                        onCancel={() => {
                           setModalError2(false);
                           setCardId('');
                           dispatch(reset());
                        }}
                     >
                        <Result
                           status='error'
                           title={
                              <>
                                 <p> {userFormCardIdMessage}</p>
                                 <p style={{ fontSize: '18px' }}></p>
                              </>
                           }
                           extra={[
                              <Button
                                 className='btn-danger'
                                 onClick={() => {
                                    setModalError2(false);
                                    setCardId('');
                                    dispatch(reset());
                                 }}
                              >
                                 {' '}
                                 OK{' '}
                              </Button>,
                           ]}
                        ></Result>
                     </Modal>
                  )}
                  {modalValidate && (
                     <Modal
                        visible={modalValidate}
                        footer={false}
                        onCancel={() => {
                           setModalValidate(false);
                        }}
                     >
                        <h5 className='m-5 text-center'>
                           {' '}
                           ตรวจสอบความถูกต้องก่อนยืนยัน{' '}
                        </h5>

                        <Form
                           labelCol={{
                              span: 6,
                           }}
                           wrapperCol={{
                              span: 18,
                           }}
                        >
                           <Form.Item label='ระดับสมาชิก'>
                              <b> {data.packge1} </b>
                           </Form.Item>
                           <Form.Item label='จำนวนรหัส'>
                              <b> {data.memberKeys} </b>
                           </Form.Item>
                           <Form.Item label='ชื่อแสดง'>
                              <b>
                                 {' '}
                                 {data.username
                                    ? data.username
                                    : 'ไม่พบข้อมูล'}{' '}
                              </b>
                           </Form.Item>
                           <Form.Item label='ชื่อจริง'>
                              <b>
                                 {' '}
                                 {data.firstName
                                    ? data.firstName
                                    : 'ไม่พบข้อมูล'}{' '}
                              </b>
                           </Form.Item>
                           <Form.Item label='นามสกุล'>
                              <b>
                                 {' '}
                                 {data.lastName
                                    ? data.lastName
                                    : 'ไม่พบข้อมูล'}{' '}
                              </b>
                           </Form.Item>
                           <Form.Item label='เลขบัตรประชาชน'>
                              <b>
                                 {' '}
                                 {data.cardId
                                    ? data.cardId
                                    : 'ไม่พบข้อมูล'}{' '}
                              </b>
                           </Form.Item>
                           <Form.Item label='เพศ'>
                              <b>
                                 {' '}
                                 {data.gender
                                    ? data.gender
                                    : 'ไม่พบข้อมูล'}{' '}
                              </b>
                           </Form.Item>
                           <Form.Item
                              className={data.email && 'text-danger'}
                              label='อีเมล์'
                           >
                              <b> {data.email ? data.email : 'ไม่พบข้อมูล'} </b>
                           </Form.Item>
                           <Form.Item label='เบอร์โทร'>
                              <b> {data.tel ? data.tel : 'ไม่พบข้อมูล'} </b>
                           </Form.Item>
                           <Form.Item label='ธนาคาร'>
                              <b> {data.bank ? data.bank : 'ไม่พบข้อมูล'} </b>
                           </Form.Item>{' '}
                           <Form.Item label='หมายเลขบัญชี'>
                              <b>
                                 {' '}
                                 {data.bankAccount
                                    ? data.bankAccount
                                    : 'ไม่พบข้อมูล'}{' '}
                              </b>
                           </Form.Item>{' '}
                        </Form>
                     </Modal>
                  )}
               </div>
            </>
         )}
      </DefaultLayout>
   );
};

const TextAddress = styled.div`
   margin-bottom: 15px;
   input {
      width: 100%;
      min-width: 0px;
      padding: 4px 11px;
      color: rgba(0, 0, 0, 0.85);
      font-size: 14px;
      line-height: 1.5715;
      background-color: rgb(255, 255, 255);
      border: 1px solid rgb(217, 217, 217);
      border-radius: 2px;
   }
`;

export default RegisterPage;
