import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Avatar, Button, List, Modal } from 'antd';
import { BsDashLg } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import { checkMemberLine } from '../../features/users/usersSlice';
import Cookies from 'js-cookie';

const SearchInput = ({ sendDataToParent }) => {
   const [query, setQuery] = useState('');

   const envMode = process.env.NODE_ENV;

   const [isError, setIsError] = useState(false);

   const [data, setData] = useState({});
   console.log(query);
   const [modalOpen, setModalOpen] = useState(false);

   const dispatch = useDispatch();

   const [userUplineSelect, setUserUplineSelect] = useState({});

   const { userInfo } = useSelector((state) => state.auth);
   const { check } = useSelector((state) => state.user);

   useEffect(() => {
      const fetchData = async () => {
         //    const res = await axios.get(`http://localhost:5000/api/users/search/${query}`, {
         const res = await axios.get(
            `//api.siamprai-login.com/api/users/search/${query}`,
            {
               headers: {
                  Authorization: `Bearer ${Cookies.get('token')}`,
               },
            }
         );
         setData(res.data);
      };

      const fecthDataByUserId = async () => {
         try {
            let res = null;
            if (envMode === 'development') {
                res = await axios.get(
                  `http://localhost:5000/api/users/searchByUserId/${query}`,
                  {
                     headers: {
                        Authorization: `Bearer ${Cookies.get('token')}`,
                     },
                  }
               );
            } else if (envMode === 'production') {
                res = await axios.get(
                  `//api.siamprai-login.com/api/users/searchByUserId/${query}`,
                  {
                     headers: {
                        Authorization: `Bearer ${Cookies.get('token')}`,
                     },
                  }
               );
            }

            setData(res.data);
            setIsError(false);
         } catch (error) {
            console.log(error);
            setIsError(true);
         }
      };

      // if (query.length > 1) {
      //    fetchData();
      //    sendDataToParent(data);
      // }
      if (query.length === 7) {
         fecthDataByUserId();
         sendDataToParent(data);
      }

      if (check === true) setModalOpen(true);
   }, [query, check]);

   const recId = localStorage.getItem('recomId');
   const recName = localStorage.getItem('recomName');

   return (
      <>
         {recId === null ? (
            <>
               <div>
                  <input
                     style={searchStyle}
                     className='search'
                     placeholder='ค้นหารหัสสมาชิก...'
                     onChange={(e) => setQuery(e.target.value.toLowerCase())}
                  />
               </div>
            </>
         ) : (
            <>
               <h5 style={resultSearch}>
                  {recId} - {recName}
               </h5>
            </>
         )}

         {isError && <b className='m-1 text-danger'> ไม่พบสิ่งที่ค้นหา </b>}

         <div className='m-2 text-danger'>
            {data.userId ? (
               <p
                  style={{ cursor: 'pointer', color: '#00bbf0' }}
                  onClick={() => {
                     setUserUplineSelect(data);
                     sendDataToParent(data);
                     localStorage.setItem('recomId', data.userId);
                     localStorage.setItem('recomName', data.name);
                     // dispatch(checkMemberLine(userUplineSelect));
                  }}
               >
                  {data.userId} - {data.name}
               </p>
            ) : null}
         </div>
      </>
   );
};

export default SearchInput;

const searchStyle = {
   padding: '4px 11px',
   border: '1px solid #d9d9d9',
   borderRadius: '2px',
   width: '100%',
};

const resultSearch = {
   borderBottom: '.5px dotted black',
};
