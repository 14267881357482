import axios from "axios";

 
  //   const API_URL = 'http://localhost:5000/api/deposit/';

     let API_URL = ''
if(process.env.NODE_ENV === 'development') {
   API_URL = 'http://localhost:5000/api/deposit/';
} else {
   API_URL =  '//api.siamprai-login.com/api/deposit/';

}


const createDeposit = async (data,token) => {

    const config = {
        headers: {
           Authorization: `Bearer ${token}`,
        },
     };
  
     const response = await axios.post(API_URL + 'create' , data, config);
     return response.data;
}

const getAllDeposits = async(token) =>{
    const config = {
        headers: {
           Authorization: `Bearer ${token}`,
        },
     };
  
     const response = await axios.get(API_URL + 'getAll' , config);
     return response.data;
}

const getTotalDepositRequest = async(token) =>{
   const config = {
       headers: {
          Authorization: `Bearer ${token}`,
       },
    };
 
    const response = await axios.get(API_URL + 'getTotalDepositRequest' , config);
    return response.data;
}

const getDepositsById = async(id,token) =>{
    const config = {
        headers: {
           Authorization: `Bearer ${token}`,
        },
     };
  
     const response = await axios.get(API_URL + `getById/${id}` , config);
     return response.data;
}

const getDepositsById2 = async(id,token) =>{
    const config = {
        headers: {
           Authorization: `Bearer ${token}`,
        },
     };
  
     const response = await axios.get(API_URL + `getById2/${id}` , config);
     return response.data;
}

const getUserDepositsById = async(id,token) =>{
   const config = {
       headers: {
          Authorization: `Bearer ${token}`,
       },
    };
 
    const response = await axios.get(API_URL + `getUserById/${id}` , config);
    return response.data;
}

const updateStatus = async(data,token) =>{
   const config = {
       headers: {
          Authorization: `Bearer ${token}`,
       },
    };
  
    const response = await axios.put(API_URL + `updateStatus` ,{data}, config);
    return response.data;
}
const approveDeposit = async(data,token) =>{
   const config = {
       headers: {
          Authorization: `Bearer ${token}`,
       },
    };
  
    const response = await axios.patch(API_URL + `approveDeposit/${data.id}` ,{data}, config);
    return response.data;
}


const depositService = {
    createDeposit,
    getAllDeposits,
    getDepositsById,
    getUserDepositsById,
    updateStatus,
    getTotalDepositRequest,
    getDepositsById2,
    approveDeposit
}

export default depositService;